.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.BGVideo {
  position: fixed;
  width: 100vw;
}

@media screen and (max-width: 100vh) {
  .BGVideo {
    width: auto !important;
    height: 100vh !important;
  }
}

.Content {
  backdrop-filter: blur(16px);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.Content > .Header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding-top: 120px;
}
.Header > .logo {
  shape-rendering: crispEdges;
  max-height: 40px;
}

.Header > .Subtitle {
  padding-top: 32px;
  color: white;
  opacity: 0.6;
  font-size: 16px;
}

.Header > .Title {
  padding-top: 4px;
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bolder;
}

.Header > .Description {
  padding: 16px;
  text-align: center;
  padding-top: 4px;
  color: white;
  opacity: 0.5;
  font-size: 11px;
  font-weight: normal;
}

.Content > .SearchBar {
  max-width: 700px;
  width: calc(100% - 64px);
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  border: 1px solid white;
  margin: 16px;
  display: flex;
  align-items: center;
}

.SearchBar > input {
  position: relative;
  background-color: transparent;
  display: flex;
  border: none;
  height: 32px;
  width: 100%;
  display: flex;
  padding-left: 16px;
  outline: none;
  color: white;
}

.SearchBar > input::placeholder {
  color: #fff;
  opacity: 0.5;
}

.SearchBar > img {
  padding: 0px 16px;
  transition: all 0.05s ease;
  cursor: pointer;
  user-select: none;
}

.SearchBar > img:active {
  opacity: 0.6;
}

.Toggle {
  display: flex;
  border-radius: 2px;
  border: 0.5px solid white;
  margin: 32px;
}

.Toggle > .ToggleButton {
  border: 1px solid white;
  padding: 8px;
  font-size: 0.8rem;
  color: white;
  user-select: none;
  cursor: pointer;
}

.Toggle > .ToggleButton.active {
  background-color: white;
  color: #240c44;
}

.Toggle > .ToggleButton.active:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #240c44;
}

.Toggle > .ToggleButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.DetailsWrapper {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: calc(100% - 64px);
  box-sizing: border-box;
  display: flex;
  max-width: 700px;
  margin: 16px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid white;
  flex-wrap: wrap;
  justify-content: center;
}

.DetailsWrapper > .Col {
  margin: 16px;
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  max-width: 290px;
}
.Col > .Title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}
.Col > .Field {
  margin-top: 8px;
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Field > span:first-child {
  width: 100%;
}
.Field > span:last-child {
  opacity: 0.6;
}

.Field > a {
  color: white;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.6;
}
.Field > a:active {
  opacity: 0.8;
}
.Field > span {
  display: flex;
  align-items: center;
}
.Field > a > svg {
  margin-left: 8px;
}
.Field > span > svg {
  margin-left: 8px;
}

.GenericWrapper {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: calc(100% - 64px);
  box-sizing: border-box;
  display: flex;
  max-width: 700px;
  margin: 16px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid white;
  height: fit-content;
  justify-content: center;
}
.GenericWrapper > .Title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 32px;
}
.GenericWrapper > .Body {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.ValidationLogo {
  user-select: none;
  height: 24px;
  cursor: pointer;
  transition: all 0.05s ease-out;
  margin: 8px;
}
.ValidationLogo:active {
  opacity: 0.5;
}
.ValidationLogo:hover {
  transform: scale(1.05);
}

.CollapserWrapper {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  max-width: 700px;
  flex-direction: column;
  height: fit-content;
  width: calc(100% - 64px);
  border: 1px solid white;
  color: white;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.7);
}
.CollapserWrapper > .CollapserHeader {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  padding: 16px;
}

.CollapserHeader > .icon {
  margin-right: 16px;
}
.CollapserHeader > .icon.reverse {
  transform: rotate(180deg);
}

.CollapserWrapper > .CollapserContent {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 150px;
  overflow-y: scroll;
}
.CollapserContent > .Line {
  color: white;
  opacity: 0.6;
  display: flex;
  font-size: 12px;
  padding: 8px 0px;
  text-decoration: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.CollapserContent > .Line:hover {
  opacity: 0.8;
}
.CollapserContent > .Line > a {
  width: fit-content;
  display: inline-flex;
  text-decoration: underline;
}
.CollapserContent > .Line > svg {
  width: fit-content;
  margin-left: 8px;
  display: inline-flex;
}
.CollapserContent > .Line > .right {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  text-decoration: none !important;
}
.CollapserContent > .Dots {
  position: sticky;
  bottom: -16px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.CollapserContent > .LoadMore {
  margin-top: 16px;
  font-size: 12px;
  text-decoration: underline;
  bottom: -16px;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.Line .owner {
  font-style: italic;
  font-weight: bold;
  pointer-events: none;
}
.Line .owner::after {
  content: "(your file)";
  display: inline-block;
  padding-left: 0.2rem;
}

.loader {
  margin: 32px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ErrorMessage {
  display: flex;
  max-width: 700px;
  width: calc(100% - 64px);
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding: 32px 0px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  border: 1px solid white;
  font-size: 12px;
}
.ErrorMessage > .title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 16px;
}

.ErrorMessage span:nth-child(2) {
  text-align: center;
  padding: 0 2rem;
}
.QueryList {
  position: absolute;
  top: 340px;
  z-index: 10;
  width: calc(100% - 64px);
  max-width: 700px;
  border-radius: 4px;
  overflow: hidden;
  max-height: 300px;
  overflow-y: auto;
  background: white;
}
.QueryList > .item {
  color: black;
  text-decoration: none;
  padding: 8px;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.QueryList > .item > .icon {
  padding: 0px 16px;
}
.QueryList > .item:hover {
  opacity: 1;
}

.multiple-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.multiple-document :is(h3, p) {
  margin: 0;
  padding: 0.5rem;
  text-align: center;
}

.multiple-document p {
  font-size: 12px;
  padding: 0.5rem 2rem;
}

.documents-grid {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 90%;
}

.documents-grid .DetailsWrapper {
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 250ms;
  flex-wrap: nowrap;
  gap: 0.5rem;
  width: 100%;
}

.DetailsWrapper .info.hash {
  padding-left: 0.1rem;
  cursor: pointer;
  max-width: 100%;
}

.documents-grid .DetailsWrapper:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.Line:has(span.owner) {
  text-decoration: underline;
  pointer-events: none;
}

.Additionals.Col .Field:nth-child(5) > span:nth-child(2),
.Summary.Col .Field > span:nth-child(2) {
  cursor: pointer;
}

.Content .back {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1rem;
}
